/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
// @ngInject
function states($stateProvider) {
  $stateProvider.state('dashboard', {
    parent: 'root',
    url: '/dashboard',
    templateProvider: function($templateFactory, acpConfigurationService) {
      var brand = acpConfigurationService.getBrand();
      return $templateFactory.fromUrl(
        brand === 'netspendExpense'
          ? 'apps/dashboard/templates/acp-dashboard-expense.ng.html'
          : 'apps/dashboard/templates/acp-dashboard.ng.html'
      );
    },
    resolve: {
      module: /* @ngInject */ function($ocLazyLoad) {
        return import(/* webpackChunkName: "acp.section.dashboard" */ 'apps/dashboard').then(
          $ocLazyLoad.loadModule
        );
      }
    }
  });
}

export default states;
